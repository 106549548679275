@import "src/constants.scss";

.photoviewer {
    &-container {
        background-color: rgba(0,0,0,.9);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 150;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5rem 2rem;
    }

    &-img {
        max-width: 100%;
    }

    &-closebutton {
        position: absolute;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=);
        background-size: 18px;
        background-position: center center;
        background-repeat: no-repeat;
        right: 0;
        top: 0;
        width: 5rem;
        height: 5rem;
    }
}