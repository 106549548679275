@import "src/constants.scss";

.quote-section {
    text-align: center;

    h2 {
        font-size: 5rem;
        margin: 0;

        @media screen and (max-width: $tablet-screen) {
            font-size: 3rem;
        }

        em {
            font-family: "Casta Slanted", sans-serif;
            font-weight: bold;
        }
    }

    .quote-author {
        font-size: 2rem;
    }
}