$yellow: #D9CEAD;
$darkbrown: #59554C;
$beige: #D9C9BA;
$pink: #F2E4D8;
$lightbrown: #8C847D;

$small-screen: 480px;
$tablet-screen: 768px;
$laptop-screen: 1024px;
$wide-screen: 1200px;
