@import "src/constants.scss";

header {
    position: fixed;
    height: 8vh;
    width: 100%;
    background-color: $pink;
    border-bottom: 1px solid $darkbrown;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
    font-size: 1.5rem;
    background-color: $pink;
    z-index: 100;

    @media screen and (max-width: $tablet-screen) {
        padding: 1rem;
    }

    .navbar {
        &-section ul {
            display: flex;
            list-style-type: none;
            padding: 0;
            gap: 2rem;
            margin: 0;

            @media screen and (max-width: $tablet-screen) {
                gap: 1rem;
            }
        }

        &__logo {
            display: flex;
            align-items: center;

            img {
                height: 3rem;
            }
        }
    }
}