@import "src/constants.scss";

#contact-form {
    width: 40vw;
    margin: auto;

    @media screen and (max-width: $tablet-screen) {
        width: initial;
    }

    .input-holder {
        margin-bottom: 2rem;
        
        input,
        select,
        textarea {
            padding: 0.5rem 0.4rem;;
            font-size: 1.5rem;
            display: block;
            color: $darkbrown;
            background-color: transparent;
            width: 100%;
            position: relative;
            border: 1px solid $darkbrown;
            border-color: transparent;
            border-bottom-color: $darkbrown;

            &:focus,
            &:focus-visible {
                outline: none;
                border-color: $darkbrown;
            }
        }
        
        select {
        padding-left: 0.1rem;
        }

        input::placeholder {
        color: $darkbrown;
        }
        
        input[type="submit"] {
            border-bottom-width: 0;
        
            &:focus,
            &:focus-visible {
                border-width: 0;
            }
        }
        
        textarea {
            border-color: $darkbrown;
            resize: none;
            overflow: auto;
        }
    }
        
    label {
        padding-left: 0.5rem;
        font-size: 1.5rem;
        display: block;
        color: $darkbrown;
        font-family: sans-serif;
        margin-bottom: 0.5rem;
    }

    .form-separator {
        height: 2rem;
    }
}