@import "src/constants.scss";

.hero {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    @media screen and (max-width: $small-screen) {
        gap: 1rem;
    }

    &__title {
        font-size: 4rem;
        text-align: center;
        
        &.name {
            span {
                display: inline-block;
                opacity: 0;
                animation: revealText 1s ease forwards;
                transform: translateY(50px);
            }

            span:first-child {
                animation-delay: 2s;
            }

            span:last-child {
                font-family: "Casta Slanted";
                font-weight: bold;
                animation-delay: 1.5s;
            }
        }

        &.resume {
            span {
                display: inline-block;
                opacity: 0;
                animation: revealText 1s ease forwards;
                transform: translateY(-50px);
            }

            .activity {
                font-weight: bold;
                font-family: "Casta Slanted";
                animation-delay: 2.5s;
            }

            .examples {
                font-size: 2rem;
                animation-delay: 3s;

                @media screen and (max-width: $small-screen) {
                    font-size: 1.5rem;
                }
            }
        }

        @media screen and (max-height: 1000px) {
            font-size: 2rem;
        }
    }

    &__img-container {
        width: 80vw;
        height: 50vh;
        background: url("../../assets/image/pictures/hero.jpg") center center;
        background-size: cover;
        animation: revealImage 5s ease forwards;
        opacity: 0;
        animation-delay: 1s;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    &__button {
        display: flex;
        height: 8vh;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &__link,
        &__link:before {
            position: absolute;
            left: 50%;
        }
    
        &__link {
            width: 40px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);;
            margin: -20px 0 0 -20px;
            border-left: none;
            border-top: none;
            border-right: 2px $darkbrown solid;
            border-bottom: 2px $darkbrown solid;
        }
        
        &__link:before {
            content: '';
            width: 20px;
            height: 20px;
            top: 50%;
            margin: -10px 0 0 -10px;
            border-left: none;
            border-top: none;
            border-right: 1px $lightbrown solid;
            border-bottom: 1px $lightbrown solid;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-name: arrow;
        }
    }

    &.isloaded {
        .hero__title *,
        .hero__img-container {
            animation-duration: 0s !important;
            animation-delay: 0s !important;
        }
    }
}
  
@keyframes arrow {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate(-10px, -10px)
    }
    
}

@keyframes revealImage {
    to {
        opacity: 1;
    }
}

@keyframes revealText {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}