.image-wrapper {
    width: 25%;
    height: 30rem;
    position: relative;
    overflow: hidden;
    
    .desc-box {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      position: relative;
      
      &__title,
      &__details {
        margin-bottom: 0.5rem;
        overflow: hidden;
        
        > h3,
        > span {
          transition-duration: 0.5s;
        }
        
        > h3 {
          transition-delay: 0;
          transform: translateY(1.5rem);
          font-size: 1.5rem;
        }
        > span {
            transform: translateY(1.1rem);
            &:first-child {
                transition-delay: 0.2s;
            }
            &:last-child {
                transition-delay: 0.4s;
            }
        }
      }
      
      &__title {
        h3 {
          margin: 0;
        }
      }
      
      &__details {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }
      
      &::after {
        content: "";
        display: block;
        height: 1px;
        width: 0;
        background-color: black;
        position: absolute;
        bottom: 0;
        left: 0;
        transition-duration: 0.5s;
        transition-delay: 0.6s;
      }
    }
    
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      filter: grayscale(60%);
      transition-duration: 0.5s;
    }
    
    &:hover {
      .desc-box {
        h3,
        .desc-box__details span{
          transform: translateY(0);
        }
        
        &::after {
          width: 100%;
        }
      }
    
      img {
        filter: grayscale(0%);
      }
    }
  }