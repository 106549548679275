@import './constants.scss';

@font-face {
  font-family: "Casta Slanted";
  src: url("./assets/font/Casta-ThinSlanted.otf");
}

@font-face {
  font-family: "Casta";
  src: url("./assets/font/Casta-Thin.otf");
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono');

* {
  box-sizing: border-box;
  color: $darkbrown;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: $pink;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Casta", sans-serif;
}

a {
  text-decoration: none;
}

.section-page {
  padding: 5rem .5rem;

  @media screen and (max-width: $small-screen) {
    padding-top: 3rem;
  }

  &.first-section {
    padding-top: 10rem;

    @media screen and (max-width: $small-screen) {
      padding-top: 6rem;
    }
  }

  .page-content {
    padding: 2rem;
    width: 70rem;
    max-width: 100%;
    margin: auto;

    @media screen and (max-width: $small-screen) {
      padding: 1rem;
    }
  }
}

h1,h2, h3 {
  margin: 0;
}

h2 {
  font-weight: normal;
  font-size: 7.5rem;
  margin-bottom: 5rem;

  @media screen and (max-width: $tablet-screen) {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
}

h3 {
  font-size: 3rem;

  @media screen and (max-width: $tablet-screen) {
    font-size: 2rem;
  }
}

hr {
    width: 3.5rem;
    background-color: $darkbrown;
    height: 2px;
    border: 0;
    margin: 2rem auto;
}