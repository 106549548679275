@import "src/constants.scss";

.about-section {
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        .about-section__img,
        .about-section__text {
            width: 50%;
        }

        .about-section__img {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                display: block;
                max-width: 100%;
                max-height: 40rem;
                width: auto;
                height: auto;
            }
        }

        .about-section__text {
            padding: 0 2rem;
            
            p {
                font-family: "Roboto Mono", serif;
            }
        }

        

        @media screen and (max-width: $tablet-screen) {
            flex-direction: column;
            .about-section__img {
                width: 60%;
                margin-bottom: 1rem;
            }

            .about-section__text {
                width: 100%;
                padding: 0;
            }
        }
    }
}