@import "src/constants.scss";

.footer-section.section-page {
    text-align: center;
    border-top: 1px solid $darkbrown;
    padding: 3rem;

    .page-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .copyright {
            font-weight: bold;
        }

        .developpedby {
            text-decoration: underline;
        }
    }
}