@import "src/constants.scss";

.singlework {
    padding: 0;

    &__hero {
        padding: 8vh 0 0;
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $darkbrown;
        
        &__details,
        &__picture {
            height: 100%;
            width: 50%;
        }

        &__details {
            display: flex;
            justify-content: center;
            align-items: center;
            
            @media screen and (max-width: $tablet-screen) {
                align-items: flex-end;
            }

            &--wrapper {
                width: calc(100% - 12vw);

                @media screen and (max-width: $tablet-screen) {
                    background-color: rgba(242, 228, 216, .5);
                    width: 100%;
                    padding: 2rem;
                }
            }

            &--topic {
                font-size: 1.5rem;
                margin-bottom: 2rem;
                display: inline-block;

                @media screen and (max-width: $tablet-screen) {
                    font-weight: bold;
                }

                @media screen and (max-width: $small-screen) {
                    font-size: 1rem;
                }
            }

            &--title {
                font-size: 8.5rem;
                font-weight: normal;

                @media screen and (max-width: $wide-screen) {
                    font-size: 5rem;
                }

                @media screen and (max-width: $tablet-screen) {
                    font-weight: bold;
                }

                @media screen and (max-width: $small-screen) {
                    font-size: 2rem;
                }
            }
        }

        &__picture {
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        @media screen and (max-width: $tablet-screen) {
            &__details,
            &__picture {
                width: 100%;
            }

            &__details {
                height: calc(100% - 8vh);
                position: absolute;
            }
        }
    }

    &__gallery {
        grid-column-gap: 200px;
        grid-row-gap: 200px;
        column-count: 3;
        column-rule-width: 0px;
        flex-direction: column;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        align-self: center;
        column-gap: 6vw;
        padding: 5rem 0;

        .listitem {
            max-width: 400px;
            min-width: 140px;
            justify-content: center;
            align-items: stretch;
            padding-bottom: 6vw;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            position: relative;

            img {
                max-width: 100%;
            }
        }

        @media screen and (max-width: $tablet-screen) {
            column-count: 2;
        }

        @media screen and (max-width: $small-screen) {
            column-count: 1;
        }
    }
}