@import "src/constants.scss";

.loader-wrapper {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    background-color: $pink;
    transition: transform 1.5s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;

    &.hidden {
        transform: translateY(-100vh);
    }

    .loader {
        width: 48px;
        height: 48px;
        display: inline-block;
        position: relative;
        
        &::after,
        &::before {
            content: '';
            width: 48px;
            height: 48px;
            border: 2px solid $darkbrown;
            position: absolute;
            left: 0;
            top: 0;
            box-sizing: border-box;
            animation: rotation 2s ease-in-out infinite;
        }

        &::after {
            border-color: $lightbrown;
            animation-delay: 1s;
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}