@import "src/constants.scss";

.works-section {
    h2 {
        margin-bottom: 1rem;
    }

    &__slider {
        overflow: hidden;

        &__slideshow {
            display: flex;
            align-items: stretch;
            gap: 1rem;
            width: calc((100%)  * (4 / 3));
            transition: .5s;

            &.position1 {
                transform: translateX(0);
            }
            &.position2 {
                transform: translateX(-25%);
            }
            &.position3 {
                transform: translateX(-50%);
            }
            &.position4 {
                transform: translateX(-75%);
            }

            @media screen and (max-width: $tablet-screen) {
                width: calc((100%) * (4 / 2));
            }

            @media screen and (max-width: $small-screen) {
                width: calc((100%) * (4 / 1));
            }
        }
    }

    .arrows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4rem;
        max-width: 10rem;
        margin: 2rem auto;
        
        .arrow-container {
            width: 4rem;
            height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: .5s;
        
            .arrow {
            position: relative;
            height: 1rem;
            width: 1rem;
            background-color: $darkbrown;
            transform: rotate(45deg);
            transform-origin: center;
        
            &.right {
                transform: rotate(225deg);
            }
        
            &::before,
            &::after {
                content: '';
                position: absolute;
                display: block;
                bottom: 0;
                left: 0;
                width: 1.1rem;
                height: 1.1rem;
                border-left: 2px solid $lightbrown;
                border-bottom: 2px solid $lightbrown;
                transform: translate(-.6rem, .6rem);
                transition: .5s;
            }
        
            &::after {
                opacity: 0;
            }
            }
        
            &:hover {
                transform: scale(1.1);
                
                .arrow::after {
                    transform: translate(-1.2rem, 1.2rem);
                    opacity: 1;
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }
        }
    }
}