h2 {
    .outlined {
        position: relative;
        padding-left: 7rem;
      
        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            content: "";
            display: block;
            background: black;
            height: 1px;
            width: 5rem;
        }
    }
}